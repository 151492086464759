<template>
  <section id="pricing-plan">
    <div class="text-center pb-75">
      <h1 class="mt-1">Pricing Plans</h1>
      <!-- <p class="mb-1">Choose the best plan to fit your needs.</p> -->
      <b-form-group v-if="pricingPlan">
        <b-form-radio-group
          id="btn-radios-1"
          v-model="selectedRadio"
          button-variant="outline-primary"
          :options="optionsRadio"
          @input="togglePlan(selectedRadio)"
          buttons
          name="radios-btn-default"
        />
      </b-form-group>
    </div>

    <!-- pricing plan cards -->
    <transition name="slide">
      <b-row class="pricing-card" v-if="pricingPlan">
        <b-col
          offset-sm-2
          sm="10"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto d-flex justify-content-center"
        >
          <b-row>

            <template v-if="loading">
              <div class="d-flex justify-content-center" >
                <b-spinner variant="primary"></b-spinner>
              </div>
            </template>
            
            <template v-else>
              <b-col md="3" v-for="pack in packs" :key="pack.id">
                <b-card
                  :class="{
                    current: pack.id == currentPack,
                    popular: pack.popular == 1 && pack.id != currentPack,
                  }"
                  align="center"
                >
                <div v-if="pack.popular == 1" class="pricing-badge text-right" style="position: absolute; top: 10px; right: 10px; z-index: 9999;">
                  <b-badge variant="light-primary" pill> Popular </b-badge>
                </div>

                 
                  <h3>{{ pack.title }}</h3>

                  <div class="pricing-badge text-center">
                    <b-badge variant="light-success">Save {{ pack.offer }}%</b-badge>
                  </div>

                  <b-card-text v-if="pack.sub_title">
                    {{ pack.sub_title }}
                  </b-card-text>

                  <div v-if="pack.price">
                    <div class="plan-price">

                      <span
                        v-if="
                          monthlyPlanShow === 'yearly' 
                        "
                          class="pricing-basic-value larger-price font-weight-bolder text-primary"
                          
                        >
                        ${{ pack.monthly_price }}/month

                            <del class="pricing-basic-value font-weight-bolder text-secondary small">
                              ${{ pack.discountless_price }}/mo
                            </del>
                      </span>

                      <template v-else-if="monthlyPlanShow === 'monthly'">
                          <span
                          
                            class="pricing-basic-value larger-price font-weight-bolder text-primary"
                          >
                            ${{ pack.price }}/month
                          </span>

                          <!-- <b-badge variant="light-success">Save {{ pack.offer }}%</b-badge> -->
                          
                          <div>
                            <del class="pricing-basic-value font-weight-bolder text-secondary">
                              ${{ pack.discountless_price }}/mo
                            </del>
                          </div>

                      </template> 

                      <template v-else>
                        <span
                          
                            class="pricing-basic-value larger-price font-weight-bolder text-primary"
                          >
                            ${{ pack.price }}
                          </span>

                          <!-- <b-badge variant="light-success">Save {{ pack.offer }}%</b-badge> -->
                          
                          <div>
                            <del class="pricing-basic-value font-weight-bolder text-secondary">
                              ${{ pack.discountless_price }}
                            </del>
                          </div>
                      </template>
                      
                    </div>

                    <small
                      v-show="
                        monthlyPlanShow === 'yearly' ||
                        (monthlyPlanShow === 'life_time' &&
                          pack.billing_cycle === 'yearly')
                      "
                      class="annual-pricing text-muted"
                    >
                      $ {{ pack.price }} / year
                    </small>
                  </div>

                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item>Max User: {{ pack.max_user }} users</b-list-group-item>
                    <b-list-group-item>Role and Permissions</b-list-group-item>
                    <b-list-group-item
                      >Human Capital Management</b-list-group-item
                    >
                    <b-list-group-item>Leave Management</b-list-group-item>
                    <b-list-group-item>Financial Management</b-list-group-item>
                    <b-list-group-item>Task Management</b-list-group-item>
                    <b-list-group-item>Asset Management</b-list-group-item>
                    <b-list-group-item>Loan Management</b-list-group-item>
                    <b-list-group-item>Payroll Management</b-list-group-item>
                  </b-list-group>


                  <b-button
                    v-if="$permissionAbility(SUBSCRIPTION_EDIT, permissions) && pack.id == currentPack"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="outline-success"
                  >
                    Your Current Plan
                  </b-button>


                  <b-button
                     v-else-if="$permissionAbility(SUBSCRIPTION_EDIT, permissions) && pack.id != currentPack"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="primary"
                    @click="
                      pack.price <= 0
                        ? goContactPage()
                        : createSubscription(pack.id)
                    "
                  >
                    Change Subscription
                  </b-button>

                  <b-button
                    v-else-if="!$permissionAbility(SUBSCRIPTION_EDIT, permissions) && pack.id == currentPack"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="outline-success"
                  >
                    Your Current Plan
                  </b-button>

                  <div class="text-left">
                    <p v-if="pack.id == currentPack && cancelSub !== 1"
                      variant="danger"
                      @click="cancelSubscription"
                    >
                      <b-link class="font-weight-bold text-warning small" @click.stop="cancelSubscription">
                        Cancel Subscription
                      </b-link>

                    </p>

                    <p v-else-if="pack.id == currentPack && cancelSub === 1"
                      variant="danger"
                    >
                      <b-link class="font-weight-bold text-warning small" >
                        Your subscription is already canceled. 
                      </b-link>

                    </p>


                  </div>

                </b-card>

              </b-col>
            </template>

          </b-row>
        </b-col>
      </b-row>
    </transition>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BLink,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BForm,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BAlert,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  SUBSCRIPTION_EDIT,
} from "@/helpers/permissionsConstant";

export default {
  name: "Subscriptions",
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BLink,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BAlert,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      // Permissions
      SUBSCRIPTION_EDIT,

      email: "",
      packageId: "",
      companyName: "",

      nameError: false,
      emailError: false,

      status: "monthly",
      monthlyPlanShow: "monthly",
      pricing: {},

      packs: [],

      pricingPlan: true,

      selectedRadio: "monthly",

      optionsRadio: [
        { text: "Monthly", value: "monthly" },
        { text: "Yearly", value: "yearly" },
        { text: "Life Time", value: "life_time" },
      ],

      currentPack: "",
      loading: false,
      cancelSub: "",

    };
  },

  computed: {
    ...mapGetters({
      authUser: "userModule/getUser",
      permissions: "userModule/getPermissions",

    }),
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getSubscriptionPricingItems() {
      return await this.$api.get("api/subscription-pricing");
    },

    async getCurrentPackage(params) {
      return await this.$api.get("api/current-subscription-package", {
        params: {
          company_id: params.company_id,
        },
      });
    },

    async getCancelSubscription(params) {
      return await this.$api.get("api/cancel-subscription", {
        params: {
          company_id: params.company_id,
        },
      });
    },

    async cancelSubscription() {
      const response = await this.getCancelSubscription({
        company_id: this.authUser?.company_id,
      });

      if (response.data.status == "success") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: response.data.message,
          },
        });

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: response.data.message,
          },
        });
      }
    },

    async loadItems() {

      this.loading = true;

      const getSubscriptionPricingItems =
        await this.getSubscriptionPricingItems({});

      const getCurrentPackages = await this.getCurrentPackage({
        company_id: this.authUser?.company_id,
      });

      this.currentPack = getCurrentPackages.data?.data?.package_id;
      this.cancelSub = getCurrentPackages.data?.data?.sub_is_cancel;

      if (this.monthlyPlanShow === "monthly") {
        this.packs = getSubscriptionPricingItems.data.filter(
          (pack) => pack.billing_cycle === "monthly"
        );
      } else if (this.monthlyPlanShow === "yearly") {
        this.packs = getSubscriptionPricingItems.data.filter(
          (pack) => pack.billing_cycle === "yearly"
        );
      } else {
        this.packs = getSubscriptionPricingItems.data.filter(
          (pack) => pack.billing_cycle === "life_time"
        );
      }

      this.loading = false;

    },

    async createSubscription(packageId) {
      const response = await this.$api.post("/api/update-subscription", {
        company_id: this.authUser?.company_id,
        package_id: packageId,
      });

      if (response.data.status == "success") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: response.data.message,
          },
        });

        setTimeout(() => {
          this.loadItems();
        }, 2000);

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: response.data.message,
          },
        });
      }
    },

    togglePlan(selectedRadio) {
      if (selectedRadio === "monthly") {
        this.monthlyPlanShow = "monthly";
        this.loadItems();
      } else if (selectedRadio === "yearly") {
        this.monthlyPlanShow = "yearly";
        this.loadItems();
      } else {
        this.monthlyPlanShow = "life_time";
        this.loadItems();
      }
    },

    goContactPage() {
      window.location.href = "https://projectx.zone/contact-us/";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.current {
  border: 1px solid #28c76f;
}

.larger-price {
  font-size: 2rem; 
}

.custom-link {
  color: #7367f0;
}
</style>